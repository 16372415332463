import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import { Sidebar } from '../components/Sidebar';
import Alert from '../components/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import {  EditSVGIcon, SearchSVGIcon } from '@react-md/material-icons';
import { get_seller_details } from '../api_handlers/dashboard_api';
import { change_password } from '../api_handlers/account_api';



const wrapper = {
	display: 'flex',
	height: 'calc(100vh)',
	background:'rgb(250,250,250)'
}

const page_wrapper = { flexGrow: 2, flexFlow: 'column', alignItems: 'flex-start', paddingLeft: '12.5rem', overflowY: 'auto', width: '100vw', height: '100vh' }
const collapsed_page_wrapper = { flexGrow: 2, flexFlow: 'column', alignItems: 'flex-start', paddingLeft: '6.5rem', overflowY: 'auto', width: '100vw', height: '100vh' }

const details_wrap = {
    display:"flex",
    flexFlow:'column',
    padding:16,
    alignItems:'flex-start',
    justifyContent: 'space-evenly'
}

const details = {
    display:'flex',
    alignItems:'baseline',
    gap:'2rem'
}

const error_form = {
  color:'red',
  padding:'6px 10px'
}

export async function getServerData() {
    const data = await get_seller_details()
  
    return {
      props: {
        data,
      },
    }
  }
  

export default function Account(){
	if(typeof window !== 'undefined'){
    
  
    // let {data} = serverData;
  const collapsed_state = localStorage.getItem('collapsed');
  const [data , setData] = useState()
	const [error , setError] = useState(false);
	const [collapsed , setCollapsed] = useState(false);

  const [changer , setChanger] = useState(false);
  const [ changedData , setChangedData ] = useState({msg:'',heading:""})
  const [Tag , setTag] = useState('');

  const [ current , setCurrent ] = useState('');
  const [ confirm , setConfirm ] = useState('');
  const [ newpass , setNewPass ] = useState('');

  const [ currentError , setCurrentError ] = useState('');
  const [ confirmError , setConfirmError ] = useState('');
  const [ newpassError , setNewPassError ] = useState('');

  const [ showAlert , setShowAlert ] = useState(false)
    
    useEffect(()=>{
        get_seller_details()
		.then(data=>{
            console.log(data)
			if(data){
                setData(data.seller)
			}
			else{
                setError('We weren\'t able to get your data, please try again later')
			}
		})
		.catch(e=>{
            console.log(e)
            setError('Something Went wrong While looking for Account details ')
		})
    },[]);
    
    useEffect(()=>{
        console.log(data)
    },[])
	
    const toggleChanger = (tag)=>{
      setChanger(true);
      setTag(tag)
    };

    const changePassword = (e)=>{
      console.log(e)
      
      setCurrentError('');
      setConfirmError('');
      setNewPassError('');
      if(!current){
        setCurrentError('Cannot be left blank')
      }
      if(!confirm){
        setConfimError('Cannot be left blank')
      }
      if(!newpass){
        setNewPassError('Cannot be left blank')
      }

      if(newpass !== confirm){
        console.log(newpass , confirm)
        setConfirmError('Passwords Do not match');
        return
      }
      change_password(current , newpass)
      .then(response=>{
        let {msg , success} = response;
        if(!success){
          setCurrentError(msg);
          return
        }
        scrollTo(0,0)
        setShowAlert(true);
        setChangedData({msg:"Password have been changed",heading:"Success"})
      })

    }
  
    return(
        <Layout>
        <div id="wrapper" style={wrapper}>
        <Sidebar selected='account' collapsed={collapsed}  setCollapsed={setCollapsed}/>
				{
                    (error || !data) ? 
				<div style={{width: '100vw', height: 'calc(100vh)',background:'azure',display:'grid',placeItems:'center'}}>
				<p>{error}</p>
				</div>
					:
				<div style={
                    collapsed?
                    page_wrapper
                    :
					 collapsed_page_wrapper
					}>
          {
            showAlert?
          <Alert msg={changedData.msg} variant="success" heading={changedData.heading}/>
          :null
          }
                    <h3 style={{padding:'0 1rem'}}>Account Details</h3>
                    <div style={details_wrap}>
                    <div style={details}><h5>Shop Name: </h5><p>{data.shop_name || ''}</p></div>
                    <div style={details}><h5>Number: </h5><p>{data.number}</p></div>
                    <div style={details}><h5>Email: </h5><p>{data.email || ''}</p></div>
                    <div style={details}><h5>Currency: </h5><p>{data.currency || 'HKD'}</p></div>
                    <div style={details}><h5>Address: </h5>
                    <div>
                    <ul style={{listStyle:'none', padding:'1rem' , background:'#9994' , boxShadow:'inset 0px 0px 10px #999'}}>
                      <li style={{display:'flex',gap:'1rem'}}><p>House:</p><span>{data.address?data.address.house:''}</span></li>
                      <li style={{display:'flex',gap:'1rem'}}><p>Building:</p><span>{data.address?data.address.building:''}</span></li>
                      <li style={{display:'flex',gap:'1rem'}}><p>Street:</p><span>{data.address?data.address.street:''}</span></li>
                      <li style={{display:'flex',gap:'1rem'}}><p>City:</p><span>{data.address?data.address.city:''}</span></li>
                      
                    </ul></div>
                    </div>
                    <hr style={{width:'100%'}}/>
                    <h3 style={{color:'#7071e7'}}>Change Password</h3>
                    <Form no-validate="true" onKeyPress={(e)=>{if(e.key === "13"){changePassword()}}}>
                    <Form.Label name="current" for="current">Current Password</Form.Label>
                    <Form.Control type="password" id="current" placeholder="Enter existing Password" onChange={(e)=>setCurrent(e.target.value)}></Form.Control>
                    {currentError.length ? <p style={error_form}>{currentError}</p>:null}
                    <Form.Label name="new" for="new">New Password</Form.Label>
                    <Form.Control type="password" id="new" placeholder="Enter New Password to reset" onChange={(e)=>setNewPass(e.target.value)}></Form.Control>
                    {newpassError.length ? <p style={error_form}>{newpassError}</p>:null}
                    <Form.Label name="confirm" for="confirm">Confirm Password</Form.Label>
                    <Form.Control type="password" id="confirm" placeholder="Confirm New Password" onChange={(e)=>setConfirm(e.target.value)}></Form.Control>
                    {confirmError.length ? <p style={error_form}>{confirmError}</p>:null}
                    <br/>
                    <Button variant='warning' type="submit" onClick={(e)=>changePassword(e)}>Reset</Button>
                    </Form>
                    </div>
					
				</div>
				}
			</div>
		</Layout>
    )
}
}